@charset "UTF-8";

@import "_setting.scss";
@import "_reset.scss";


/* =====================
	備中玉島みなと朝市
 ======================= */
/** -------------------- 変数定義 -------------------- **/
:root {
	/* カラー定義 */
	--c_text: #000000;
	--c_wht: #fff;
	--c_red: #cd3b27;
	--c_base: #efe6d3;		/* beige */
	
	/* フォント定義 */
	--font_ja: 'Noto Sans JP', sans-serif;
	
	/* transition定義 */
	--anim: all 0.4s ease-out;
}

body {
	font-family: var(--font_ja);
	//font-size: vw(16);
	font-size: 16px;
	font-size: clamp(12px, 0.83333vw, 0.83333vw);
	line-height: (33 / 16);
	letter-spacing: 0;
	font-feature-settings: "palt";
	color: var(--c_text);
	
	* {
		box-sizing: border-box;
	}
}

img {
	max-width: 100%;
	display: block;
}

.clearfix {
	clear: both;
}

a {
	color: var(--c_text);
	transition: var(--anim);
	text-decoration: none;
	
	&:hover {
		opacity: 0.7;
	}
}

/*********** COMMON STYLE ***********/
.c-t_center {
	text-align: center;
}

.c-t_right {
	text-align: right;
}

.c-t_left {
	text-align: left;
}

.c-t_bold {
	font-weight: 700;
}

.p-inner {
	width: per(1000);
	margin: 0 auto;
}

.p-section {
	padding: per(50) 0;
}

.p-btn {
	display: block;
	margin: 0 auto;
	filter: drop-shadow(em(9) em(9) 0px rgba(#000, 0.2));
	
	&:hover {
		opacity: 1.0;
		transform: translate(em(9), em(9));
		filter: none;
	}
}

/*********** HEADER ***********/
.p-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-top: per(100);
	z-index: 1000;
	background: var(--c_red);
	
	&__container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding: em(28) em(72);
	}
	
	&__logo {
		margin-right: auto;
		width: em(359);
	}
	
	&__gnav {
		&__lists {
			display: flex;
			column-gap: em(60);
		}
		&__list {
			font-size: fs(24);
			font-weight: 600;
			line-height: 1.0;
			
			a {
				color: var(--c_base);
				position: relative;
				
				&:before {
					content: "";
					width: 0;
					height: 0.1em;
					background: currentColor;
					position: absolute;
					bottom: -0.25em;
					left: 0;
					transition: var(--anim);
				}
				
				&:hover {
					opacity: 1.0;
					
					&:before {
						width: 100%;
					}
				}
			}
		}
	}
}

/*********** FOOTER ***********/
.p-footer {
	background: url(../img/common/footer_bg01.png) no-repeat top right /cover, var(--c_base);
	padding: per(315) 0 per(85);
	color: var(--c_wht);
	position: relative;
	
	&:before {
		content: "";
		width: per(410);
		padding-top: per(617);
		background: url(../img/common/footer_img01.png) no-repeat center /contain;
		position: absolute;
		top: 0;
		margin-top: per(-30);
		left: per(222);
	}
	
	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		column-gap: per(105, 1000);
	}
	
	&__logo {
		width: per(235, 1000);
	}
	
	&__txtBox {
		font-size: fs(18);
		line-height: (29.2 / 18);
		
		dl {
			display: grid;
			grid-template-columns: auto 1fr;
		}
	}
}

/* PCのみ
  ------------------------ */
@media (min-width: $breakpoint_pc) {
	.u-onlySP {
		display: none!important;
	}
}

/* タブレット
  ------------------------ */
@media (max-width: $breakpoint_tb) {
	/*********** COMMON STYLE ***********/
	.p-inner {
	}
	
	/*********** HEADER ***********/
	.p-header {
		&__container {
			padding: em(8) em(24);
		}
		
		&__logo {
			width: em(220);
		}
		
		&__gnav {
			&__lists {
				column-gap: em(32);
			}
			&__list {
				font-size: fs(16);
			}
		}
	}
}

/* スマホ
  ------------------------ */
@media (max-width: $breakpoint_sp) {
	body {
		font-size: 16px;
	}
	
	.u-onlyPC {
		display: none!important;
	}
	
	//*********** COMMON STYLE ***********/
	.p-inner {
		width: 92%;
	}
	
	.p-section {
		padding: 32px 0;
	}
	
	.p-btn {
		filter: drop-shadow(5px 5px 0px rgba(#000, 0.2));
	}
	
	//*********** HEADER ***********/
	.p-header {
		padding-top: 50px;
		
		&__container {
			padding: 0 0 0 4%;
		}
		
		&__logo {
			width: (359px / 2);
		}
		
		&__menuBtn {
			width: 50px;
			height: 50px;
			background: #654645;
			position: relative;
			
			&__inner {
				width: per(54, 100);
				height: per(35, 100);
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
			
			span {
				width: 100%;
				height: 2px;
				background: var(--c_wht);
				position: absolute;
				left: 0;
				transition: var(--anim);
				z-index: 2000;
				
				&:first-child {
					top: 0;
				}
				&:nth-child(2), &:nth-child(3) {
					top: 0;
					bottom: 0;
					margin: auto;
				}
				&:last-child {
					bottom: 0;
				}
			}
			
			&.is-open {
				span {
					&:first-child, &:last-child {
						opacity: 0;
					}
					&:nth-child(2) {
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						transform: rotate(-45deg);
					}
				}
			}
		}
		
		&__gnav {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1000;
			background: var(--c_red);
			padding: 45px 0;
			display: none;
			
			&__lists {
				flex-direction: column;
			}
			
			&__list {
				font-size: 16px;
				font-weight: 500;
				line-height: (65 / 32);
				letter-spacing: 0.1em;
				text-align: center;
				
				a {
					color: var(--c_wht);
				}
			}
			
			&__link {
				margin-top: 20px;
			}
			
			&__btn {
				width: (373px / 2);
			}
		}
	}
	
	/*********** FOOTER ***********/
	.p-footer {
		background-position: top right 15%;
		padding: 24% 0 5%;
		
		&:before {
			left: 2%;
			transform: scale(1.5);
			transform-origin: top left;
		}
		
		&__container {
			flex-direction: column;
			gap: 20px 0;
		}
		
		&__logo {
			width: 60px;
		}
		
		&__txtBox {
			font-size: 14px;
		}
	}
}

