@charset "UTF-8";
/* =====================
  リセット
 ======================= */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

i, address {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

small {
  font-size: 100%;
}

/* =====================
	備中玉島みなと朝市
 ======================= */
/** -------------------- 変数定義 -------------------- **/
:root {
  /* カラー定義 */
  --c_text: #000000;
  --c_wht: #fff;
  --c_red: #cd3b27;
  --c_base: #efe6d3;
  /* beige */
  /* フォント定義 */
  --font_ja: 'Noto Sans JP', sans-serif;
  /* transition定義 */
  --anim: all 0.4s ease-out;
}

body {
  font-family: var(--font_ja);
  font-size: 16px;
  font-size: clamp(12px, 0.83333vw, 0.83333vw);
  line-height: 2.0625;
  letter-spacing: 0;
  font-feature-settings: "palt";
  color: var(--c_text);
}

body * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

.clearfix {
  clear: both;
}

a {
  color: var(--c_text);
  transition: var(--anim);
  text-decoration: none;
}

a:hover {
  opacity: 0.7;
}

/*********** COMMON STYLE ***********/
.c-t_center {
  text-align: center;
}

.c-t_right {
  text-align: right;
}

.c-t_left {
  text-align: left;
}

.c-t_bold {
  font-weight: 700;
}

.p-inner {
  width: 52.08333%;
  margin: 0 auto;
}

.p-section {
  padding: 2.60417% 0;
}

.p-btn {
  display: block;
  margin: 0 auto;
  filter: drop-shadow(0.5625em 0.5625em 0px rgba(0, 0, 0, 0.2));
}

.p-btn:hover {
  opacity: 1.0;
  transform: translate(0.5625em, 0.5625em);
  filter: none;
}

/*********** HEADER ***********/
.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 5.20833%;
  z-index: 1000;
  background: var(--c_red);
}

.p-header__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.75em 4.5em;
}

.p-header__logo {
  margin-right: auto;
  width: 22.4375em;
}

.p-header__gnav__lists {
  display: flex;
  column-gap: 3.75em;
}

.p-header__gnav__list {
  font-size: 150%;
  font-weight: 600;
  line-height: 1.0;
}

.p-header__gnav__list a {
  color: var(--c_base);
  position: relative;
}

.p-header__gnav__list a:before {
  content: "";
  width: 0;
  height: 0.1em;
  background: currentColor;
  position: absolute;
  bottom: -0.25em;
  left: 0;
  transition: var(--anim);
}

.p-header__gnav__list a:hover {
  opacity: 1.0;
}

.p-header__gnav__list a:hover:before {
  width: 100%;
}

/*********** FOOTER ***********/
.p-footer {
  background: url(../img/common/footer_bg01.png) no-repeat top right/cover, var(--c_base);
  padding: 16.40625% 0 4.42708%;
  color: var(--c_wht);
  position: relative;
}

.p-footer:before {
  content: "";
  width: 21.35417%;
  padding-top: 32.13542%;
  background: url(../img/common/footer_img01.png) no-repeat center/contain;
  position: absolute;
  top: 0;
  margin-top: -1.5625%;
  left: 11.5625%;
}

.p-footer__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 10.5%;
}

.p-footer__logo {
  width: 23.5%;
}

.p-footer__txtBox {
  font-size: 112.5%;
  line-height: 1.62222;
}

.p-footer__txtBox dl {
  display: grid;
  grid-template-columns: auto 1fr;
}

/* PCのみ
  ------------------------ */
@media (min-width: 700px) {
  .u-onlySP {
    display: none !important;
  }
}

/* タブレット
  ------------------------ */
@media (max-width: 1100px) {
  /*********** COMMON STYLE ***********/
  /*********** HEADER ***********/
  .p-header__container {
    padding: 0.5em 1.5em;
  }
  .p-header__logo {
    width: 13.75em;
  }
  .p-header__gnav__lists {
    column-gap: 2em;
  }
  .p-header__gnav__list {
    font-size: 100%;
  }
}

/* スマホ
  ------------------------ */
@media (max-width: 699px) {
  body {
    font-size: 16px;
  }
  .u-onlyPC {
    display: none !important;
  }
  .p-inner {
    width: 92%;
  }
  .p-section {
    padding: 32px 0;
  }
  .p-btn {
    filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.2));
  }
  .p-header {
    padding-top: 50px;
  }
  .p-header__container {
    padding: 0 0 0 4%;
  }
  .p-header__logo {
    width: 179.5px;
  }
  .p-header__menuBtn {
    width: 50px;
    height: 50px;
    background: #654645;
    position: relative;
  }
  .p-header__menuBtn__inner {
    width: 54%;
    height: 35%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .p-header__menuBtn span {
    width: 100%;
    height: 2px;
    background: var(--c_wht);
    position: absolute;
    left: 0;
    transition: var(--anim);
    z-index: 2000;
  }
  .p-header__menuBtn span:first-child {
    top: 0;
  }
  .p-header__menuBtn span:nth-child(2), .p-header__menuBtn span:nth-child(3) {
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .p-header__menuBtn span:last-child {
    bottom: 0;
  }
  .p-header__menuBtn.is-open span:first-child, .p-header__menuBtn.is-open span:last-child {
    opacity: 0;
  }
  .p-header__menuBtn.is-open span:nth-child(2) {
    transform: rotate(45deg);
  }
  .p-header__menuBtn.is-open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .p-header__gnav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background: var(--c_red);
    padding: 45px 0;
    display: none;
  }
  .p-header__gnav__lists {
    flex-direction: column;
  }
  .p-header__gnav__list {
    font-size: 16px;
    font-weight: 500;
    line-height: 2.03125;
    letter-spacing: 0.1em;
    text-align: center;
  }
  .p-header__gnav__list a {
    color: var(--c_wht);
  }
  .p-header__gnav__link {
    margin-top: 20px;
  }
  .p-header__gnav__btn {
    width: 186.5px;
  }
  /*********** FOOTER ***********/
  .p-footer {
    background-position: top right 15%;
    padding: 24% 0 5%;
  }
  .p-footer:before {
    left: 2%;
    transform: scale(1.5);
    transform-origin: top left;
  }
  .p-footer__container {
    flex-direction: column;
    gap: 20px 0;
  }
  .p-footer__logo {
    width: 60px;
  }
  .p-footer__txtBox {
    font-size: 14px;
  }
}
